.site-footer
{
  background: #000;
  padding: $grid-gutter-width-base 0;
  z-index: 1;
  transition: left .5s;

  .block-contact-form-wrapper {
    background: #fff;
    //padding: 30px 0;
    margin: 0 0 30px 0;
    display: none;
    opacity: 0;
  }

  &.content-display-contact-form {
    padding: 0 0 $grid-gutter-width-base 0;
      .block-contact-form-wrapper {
        display: block;
      }
  }

  &.nav-open
  {
    left: 285px;
  }

  @include media-breakpoint-up(md)
  {
    //padding-top: $grid-gutter-width-base * 2;
    padding-bottom: 0;
  }
}

body.hundred-proof-sticky-footer {
  .site-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.nav-footer-primary {
  padding-left: 0;
  margin-bottom: $grid-gutter-width-base;
  list-style-type: none;

  li {
    display: block;
    text-align: center;

    @include media-breakpoint-up(sm) {
      text-align: left;
    }

    &:not(:last-child)
    {
      margin-bottom: $grid-gutter-width-base / 2;
    }

    a {
      font-size: $font-size-xl;
      color: $brand-primary;
    }
  }

  @include media-breakpoint-up(lg)
  {
    .nav-main-item {
      transition: transform 300ms ease-in-out;

      &:hover {
        transform: translateX(15px);
      }
    }
  }

}

.footer-address-details, .footer-contact-details
{
  padding-left: 0;
  list-style-type: none;
  margin-bottom: $grid-gutter-width-base;

  li
  {
    display: block;
    text-align: center;

    @include media-breakpoint-up(sm)
    {
      text-align: left;
    }

    &:not(:last-child)
    {
      margin-bottom: 7px;
    }

    p
    {
      font-size: $font-size-sm;
      color: $brand-secondary;
      margin-bottom: 0;
    }
  }
}

.footer-address-details, .footer-contact-details, .nav-footer-sub-menu
{
  a, p
  {
    font-family: $body-font-family;
    font-size: $font-size-sm;
  }
}

.nav-footer-sub-menu
{
  margin-bottom: $grid-gutter-width-base;
  ul
  {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;

    li
    {
      text-align: center;

      @include media-breakpoint-up(sm)
      {
        text-align: left;
      }

      &:not(:last-child)
      {
        margin-bottom: 7px;
      }

      a
      {
        color: $brand-secondary;
      }
    }
  }
}

.copyright-text
{
  color: $brand-secondary;
  padding-top: $grid-gutter-width-base;
  display: inline-block;
  font-family: 'Raleway', sans-serif;
  font-size: $font-size-sm;

  @include media-breakpoint-up(md)
  {
    padding: $grid-gutter-width-base 0;
  }

  a:hover
  {
    color: #8dc63f;
  }
}

.footer-social-elements
{
  padding-left: 0;
  margin-bottom: $grid-gutter-width-base;
  list-style-type: none;
  text-align: center;

  @include media-breakpoint-up(md)
  {
    margin-left: 20px;
    text-align: left;

    li
    {
       &:first-child
       {
         margin-left: 0;
         margin-right: 10px;
       }

      margin-left: 10px;

      &:not(:last-child)
      {
        margin-right: 10px;
      }
    }
  }

  li
  {
    display: inline;
    margin: 0 10px;

    a
    {
      font-size: 1.5rem;
      color: $brand-primary;
    }
  }
}

.footer-copyright-container
{
  border-top: 1px solid $brand-primary;
}