@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin overlay($bgColor: #000, $bgOpacity: 0.6){
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba($bgColor, $bgOpacity);
    z-index: 0;
  }

  > .content {
    z-index:	2;
  }
}

.main
{
  padding-left: 0!important;
  padding-right: 0!important;
}

.wrap
{
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 20px 50px -20px rgba(0,0,0,.6);
}

.nav-main-link
{
  font-family: $body-font-family;
}

.flex-col
{
  display: flex;

  &.flex-col-center
  {
    align-items: center;
  }
}

.no-gutter
{
  padding-left: 0!important;
  padding-right: 0!important;
}

.square, .rectangle
{
  max-width: 100%;
  dispaly: block;
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.square
{
  &:after
  {
    content: '';
    display: table;
    padding-bottom: 100%;
  }

}


.rectangle
{
  &:after
  {
    content: '';
    display: table;
    padding-bottom: 125%;
  }
}

.order-first
{
  order: 1;
}

.order-sm-first
{
  @include media-breakpoint-up(sm)
  {
    order: 1;
  }
}

.order-md-first
{
  @include media-breakpoint-up(md)
  {
    order: 1;
  }
}

.order-lg-first
{
  @include media-breakpoint-up(lg)
  {
    order: 1;
  }
}

.order-second
{
  order: 2;
}

.order-sm-second
{
  @include media-breakpoint-up(sm)
  {
    order: 2;
  }
}

.order-md-second
{
  @include media-breakpoint-up(md)
  {
    order: 2;
  }
}

.order-lg-second
{
  @include media-breakpoint-up(lg)
  {
    order: 2;
  }
}

.embed-container {
  position: relative;
  padding-top: 56.25%;
  //padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//html body header.site-header.video-header div.hidden
//{
//  opacity: 0;
//}


.page-builder-block
{
  margin-bottom: $grid-gutter-width-base * 3;
}

body
{
  position: relative;
  left: 0;
  transition: left .5s;
  overflow-x: hidden;
}

.featherlight-content
{
  background: transparent;
  padding: 0!important;
  border-bottom: 0!important;
  max-width: 90%;
}

.featherlight-content
{
  background: transparent!important;
}

/* Tablets */
@media screen and (min-width: 768px)
{
  .featherlight-content
  {
    width: 600px;
    height: 400px;

    .featherlight-inner
    {
      min-width: 100%;
      min-height: 100%;
    }
  }
}

p
{
  line-height: 2rem;
}

.home-portfolio-feature-wrapper .video-caption-content-wrapper
{
  position: relative;
  z-index: 999999;

  h1, h2, h3, h4, h5, h6, span, p, a, i {
    color: #fff;
  }

  //
  //& > h2, & > span
  //{
  //  color: $brand-primary;
  //}
  //
  //& > p
  //{
  //  color: #fff;
  //}

  & > span
  {
    //color: $brand-primary;
    cursor: pointer;
    font-weight: 100;
  }

  i
  {
    margin-right: 10px;
  }
}

.no-scroll
{
  overflow: hidden!important;
}

.featherlight .featherlight-inner
{
  max-width: 100%;
}

h1, .h1
{
  @include media-breakpoint-up(md)
  {
    font-size: 4rem;
  }
}

h2, .h2
{
  @include media-breakpoint-up(md)
  {
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@include media-breakpoint-up(md)
{
  html
  {
    font-size: 16px;
  }
}

 .embed-container {
   position: relative;
   padding-bottom: 56.25%;
   padding-top: 30px;
   height: 0;
   overflow: hidden;
   max-width: 100%;
   height: auto;

   iframe, object, embed {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
   }
 }

 @include media-breakpoint-up(md) {
  .featherlight.featherlight-iframe .featherlight-content {
    width: 80vw; 
    height: 80vh;
  }
}