.page-header
{
  padding-top: $grid-gutter-width-base * 2;
  padding-bottom: $grid-gutter-width-base * 2;
}

.page-builder-block
{
  margin: $grid-gutter-width-base auto;

  @include media-breakpoint-up(md)
  {
    margin: $grid-gutter-width-base * 2 auto;
  }
}

.process-image
{
  max-width: 160px;
  margin: 1.5em auto;
}

.full-width-image-banner
{
  @include aspect-ratio(16, 9);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include media-breakpoint-up(md)
  {
    @include aspect-ratio(2.7, 1);
  }
}

.image-w-caption-content-wrapper
{
  padding-top: $grid-gutter-width-base;

  @include media-breakpoint-up(md)
  {
    padding-top: 0;

    &.left
    {
      padding-left: 1.5em;
    }

    &.right
    {
      padding-right: 1.5em;
    }
  }
}

.related-items-title {
  font-family: $body-font-family;
  font-size: 1rem;
  color: #353037;
  font-weight: bold;
  line-height: 27px;

  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }
}

.portfolio-related-item-display-wrapper.news-related-items-display-wrapper
{
  @include media-breakpoint-up(sm)
  {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 25px;
  }

  .portfolio-related-item-content-wrapper.news-related-items-content-wrapper
  {
    @include media-breakpoint-up(sm)
    {
      text-align: left;
      position: relative;
      top: initial;
      left: initial;
      transform: none;
    }

    .updated // Time
    {
      font-family: $body-font-family;
      color: #fff;
      line-height: 20px;
      font-size: 1rem;
      margin-bottom: 15px;

      @include media-breakpoint-up(sm)
      {
        font-size: 1.25rem;
      }
    }

    .portfolio-related-category
    {
      color: $brand-primary;
      @include media-breakpoint-up(sm)
      {
        font-size: 2rem; // 32 / 16 -- base font size ? could be 14 may of messed up
        line-height: 2rem;
      }
    }
  }
}

.home-portfolio-feature-wrapper
{
  position: relative;

  .video-caption-wrapper
  {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: opacity 2000ms, visibility 2000ms;

    &.playing-video
    {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.page-builder-two-col_wrapper
{
  @include media-breakpoint-up(md)
  {
    padding: 0 50px;
  }
}

.block-contact-form-wrapper
{
  margin-bottom: 50px;

  @include media-breakpoint-up(md)
  {
    margin-bottom: 100px;
  }

  @include media-breakpoint-up(lg)
  {
    margin-bottom: 150px;
  }
}

.home-portfolio-feature-wrapper
{
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &::after
  {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: $content-overlay;
    text-indent: -99999;
  }
}

.introduction-hundred-proof-logo {
  width: 270px;
}