.spinner-wrapper
{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  text-indent: -9999;
  pointer-events: none;
  background-color: rgba(0,0,0,1);
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  z-index: -5000;
  visibility: hidden;
  transition: opacity 1s, visibility 1s;
}

.site-header .header-navigation-wrapper, .video-bg-hide-controls > iframe, .video-caption-wrapper:not(.portfolio-video-caption-wrapper), span.header-video-play-link
{
  transition: opacity 2000ms, visibility 2000ms;
  transition: opacity 2000ms, visibility 2000ms;
}

body
{
  &.loading-header
  {
    .site-header .header-navigation-wrapper, .video-bg-hide-controls > iframe, .video-caption-wrapper:not(.portfolio-video-caption-wrapper), span.header-video-play-link
    {
      opacity: 0!important;
      visibility: hidden!important;
      transition: opacity 2000ms, visibility 2000ms;
      transition: opacity 2000ms, visibility 2000ms;
    }
  }

  &:not(.loading-header)
  {
    .site-header .header-navigation-wrapper, .video-bg-hide-controls > iframe, .video-caption-wrapper:not(.portfolio-video-caption-wrapper), span.header-video-play-link
    {
      opacity: 1;
      visibility: visible;
    }
  }

  &.playing-video
  {
    .site-header .header-navigation-wrapper, .video-bg-hide-controls > iframe, .video-caption-wrapper:not(.portfolio-video-caption-wrapper)
    {
      opacity: 0!important;
      visibility: hidden!important;
      text-indent: -999999!important;
    }

    .videobg-mobile
    {
      display: none;
    }
  }
}

body.single-portfolio
{

  .site-header .header-navigation-wrapper, .video-bg-hide-controls > iframe, .video-caption-wrapper, span.header-video-play-link
  {
    //opacity: 1;
    //visibility: visible;
    //transition: opacity 1500ms, visibility 1500ms;
    //transition: opacity 1500ms, visibility 1500ms;
  }

  .site-header // Header Wrapper
  {
    .header-navigation-wrapper {} // Navigation
  }
  .videobg-hide-controls > iframe {} // Header Iframe
  .video-caption-wrapper {}
  span.header-video-play-link {}

  &.loading-video
  {
    .site-header .header-navigation-wrapper, .video-bg-hide-controls > iframe, .video-caption-wrapper
    {
      opacity: 0!important;
      visibility: hidden!important;
      text-indent: -999999!important;
    }

    .spinner-wrapper
    {
      opacity: 1;
      z-index: 999999999;
      text-indent: 99999;
      visibility: visible;
    }
  }

  &.playing-video
  {
    .site-header .header-navigation-wrapper, .video-bg-hide-controls > iframe, .video-caption-wrapper
    {
      opacity: 0!important;
      visibility: hidden!important;
      text-indent: -999999!important;
    }
  }


}