.process-element-wrapper
{
  max-width: 255px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;

  @include media-breakpoint-up(lg)
  {
    max-width: 320px;
  }
}

.process-element-index
{
  color: $hundred-proof-text-muted;
  font-size: 1.5rem;
}

.process-content-wrapper
{
  color: $brand-tertiary;
  text-align: center;
}

.process-title-wrapper
{
  text-align: center;
}

.process-title
{
  color: $brand-tertiary;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 0.9rem;
  display: inline-block;
  margin: 1rem 0 .5rem 0;
}

.process-icon-wrapper
{
  text-align: center;
}

.process-icon
{
  color: $brand-primary;
  font-size: 10rem;
}