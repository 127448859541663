.nav-primary
{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 0;
  display: block;
  overflow: scroll;
  background: rgba(0,0,0,1);
  transition: width .5s;
  z-index: 999999;
  padding: 70px 0;

  & > ul
  {
    padding: 0;
    list-style-type: none;

    .nav-main-dropdown-icon
    {
      display: inline;
      float: right;
      z-index: 9999;
      padding: 23px 0;
      color: #fff;
      transition: transform 150ms;

      @include media-breakpoint-up(lg)
      {
        display: none!important;
      }
    }

    .nav-main-item {

      &.menu-item-has-children {

      }

      &.list-item-active {
        & > .nav-drop
        {
          max-height: 10000px;
          opacity: 1;
          overflow: visible;
        }

        & > .nav-main-dropdown-icon
        {
          transform:rotate(90deg);
        }
      }

      & > .nav-drop {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height 150ms, opacity 150ms;
        & > .nav-drop-item {
          & > a {
            display: block;
            padding: 20px 0;
          }
        }
      }
    }

    .nav-main-link {
      display: inline-block;
      padding: 20px 0;
      width: 90%;
      color: #fff;
    }
  }
}


.mobile-menu-close
{
  position: absolute;
  top: 30px;
  left: 10px;
  color: #fff;
  background: none;
  outline: 0;
  border: none;
  z-index: 9999999;
  cursor: pointer;

  @include media-breakpoint-up(lg)
  {
    display: none!important;
  }
}


html.nav-open {
  //margin-left: 285px;
  position: fixed;
  max-width: 100%;
}

html, body
{
  transition: margin-left .5s;
  &.nav-open
  {
    .nav-primary
    {
      width: 285px;
      -webkit-transform: translate3d(0,0,0);
      padding: 70px 20px;
    }
  }
}

body > .wrap, body > .site-header {
  transition: margin-left .5s;
}

body.nav-open
{
   position: relative;
   overflow: hidden;
   height: 100%;
   width: 100%;
   margin-left: 285px;

  //&::after
  //{
  //  opacity: 1;
  //  z-index: 999999999;
  //}
}

//body
//{
//  //&::after
//  //{
//  //  transition: opacity 150ms;
//  //  content: '';
//  //  position: absolute;
//  //  top: 0;
//  //  bottom: 0;
//  //  left: 0;
//  //  right: 0;
//  //  height: 100%;
//  //  width: 100%;
//  //  background: rgba(0,0,0,0.6);
//  //  z-index: -999999999999;
//  //  opacity: 0;
//  //  transition: opacity 150ms;
//  //}
//}

.header-navigation-wrapper
{
  z-index: 99999999!important;
}

.nav-primary-mobile-menu-wrapper
{
  display: block;
  opacity: 1;
  z-index: 9999999;
  text-align: center;
  width: 100%;
  text-align: center;

  @media (min-width: 768px) and (max-width: 991px)
  {
    text-align: right;
  }
  @include media-breakpoint-up(lg)
  {
    display: none!important;
  }
}

#menu-icon {
  font-size: 1.2rem;
  color: #fff;
  i
  {
    padding-right: 10px;
  }
  @include media-breakpoint-up(lg)
  {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .nav-primary {
    position: relative;
    left: initial;
    overflow: visible;
    background: transparent;
    transition: none;
    z-index: initial;
    text-align: right;
    padding-top: initial;
    padding-right: initial;
    padding-bottom: initial;
    max-height: initial;
    visibility: visible;
    z-index: initial;
    display: block;
    height: auto;
    width: 100%;
    min-width: initial;
    padding-left: 0;
    margin-bottom: 0;
    top: initial;
    min-width: initial;

    & > ul
    {

      .nav-main-item
      {
        display: inline;
        position: relative;

        &:not(:last-of-type)
        {
          margin-right: 50px;
        }

        & > .nav-drop
        {
          list-style-type: none;
          position: absolute;
          border-top: 4px solid #fff;
          background: rgba(0,0,0,1);
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 100%);
          display: inline-block;
          width: 190px;
          padding: 0;
          max-height: 0;
          opacity: 0;
          overflow: hidden;

          transition: max-height 200ms, opacity 200ms;

          &:after {
            content: '';
            width: 0;
            height: 0;
            display: block;
            //background-color: blue;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -100%);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 15px solid #fff;
            opacity: 0;
            transition: opacity 200ms;
          }
        }

        &:hover > .nav-drop
        {
          max-height: 10000px;
          opacity: 1;
          overflow: visible;

          &:after {
            opacity: 1;
          }
        }

        li.nav-drop-item > a
        {
           text-align: center;
           padding: 20px 15px!important;
            color: #fff;
        }

        &:not(.menu-item-has-children)
        {
          & > .nav-main-link {
            &:before {
              content: "";
              position: absolute;
              z-index: -1;
              left: 51%;
              right: 51%;
              bottom: 0;
              background: #fff;
              height: 4px;
              transition-property: left,right;
              transition-duration: .3s;
              transition-timing-function: ease-out;
            }

            &:hover {
              &:before {
                left: 0;
                right: 0;
              }
            }
          }
        }
      }

      .nav-main-link
      {
        width: initial;
      }
    }
    //
    //  & > ul
    //  {
    //    display: block;
    //    width: 100%;
    //    text-align: right;
    //
    //    .nav-main-item {
    //      display: inline;
    //
    //      &:not(:last-of-type)
    //      {
    //        margin-right: 50px;
    //      }
    //    }
    //
    //    .nav-main-link
    //    {
    //
    //    }
    //  }
    //
    //}
  }
}



//@include media-breakpoint-up(lg)
//{
//  position: relative;
//  left: initial;
//  overflow: initial;
//  background: transparent;
//  transition: none;
//  z-index: initial;
//  text-align: right;
//  padding-top: initial;
//  padding-right: initial;
//  padding-bottom: initial;
//  max-height: initial;
//  visibility: visible;
//  z-index: initial;
//  display: block;
//  width: 100%;
//  padding-left: 0;
//  margin-bottom: 0;
//}
//
//.nav-main-item
//{
//  display: block;
//  text-align: left;
//  padding: 0 0 30px;
//  position: relative;
//
//  &.nav-item-has-children
//  {
//    &:after
//    {
//      content: "\f13a";
//      position: absolute;
//      top: 0;
//      right: 0;
//      color: #fff;
//      font-family: FontAwesome;
//      transition: transform .5s;
//    }
//
//    @include media-breakpoint-up(lg)
//    {
//      &:after
//      {
//        content: "";
//        position: initial;
//        top: initial;
//        right: initial;
//        color: #fff;
//        font-family: initial;
//        transition: none;
//      }
//    }
//  }
//
//  @include media-breakpoint-up(lg)
//  {
//    display: inline;
//    margin: 0 $grid-gutter-width-base / 3;
//    position: relative;
//    text-align: center;
//    padding-bottom: 0;
//
//
//    &.nav-main-item.nav-item-has-children:hover
//    {
//      & > ul
//      {
//        max-height: 1000px;
//        opacity: 1;
//        overflow: visible;
//        li
//        {
//          max-height: 1000px;
//        }
//      }
//    }
//
//    .nav-drop
//    {
//      background: rgba(0,0,0,0.9);
//      position: absolute;
//      bottom: 0;
//      left: 0;
//      transform: translateY(105%);
//      display: inline-block;
//      width: 200px;
//      opacity: 0;
//      overflow: hidden;
//
//      z-index: 99999;
//
//      li
//      {
//        max-height: 0;
//        margin: 0;
//        float: none;
//        display: block;
//        padding-bottom: 0;
//        transition: max-height 1s;
//
//        @media screen and (min-width: 769px)
//        {
//          transition: max-height 6s;
//        }
//
//        &:hover
//        {
//          a
//          {
//            color: rgba(255,255,255,1);
//          }
//        }
//
//        a
//        {
//          padding: $grid-gutter-width-base / 1.5 $grid-gutter-width-base;
//          text-align: center;
//          display: block;
//          color: rgba(255,255,255,0.8);
//          transition: color .5s;
//        }
//      }
//    }
//  }
//
//  &.list-item-active
//  {
//    .nav-drop
//    {
//      max-height: 1000px;
//      overflow: visible;
//    }
//
//    &.nav-item-has-children
//    {
//      &:after
//      {
//        transform: rotate(180deg);
//      }
//    }
//  }
//
//  .nav-drop
//  {
//    max-height: 0;
//    overflow: hidden;
//    transition: max-height 1s;
//
//    @include media-breakpoint-up(lg)
//    {
//      transition: max-height 200ms;
//      padding-left: 0;
//    }
//
//    .nav-drop-item
//    {
//
//      &:first-of-type
//      {
//        padding-top: 30px;
//
//        @include media-breakpoint-up(lg)
//        {
//          padding-top: 0;
//        }
//      }
//
//      &:last-of-type
//      {
//        padding-bottom: 0;
//      }
//    }
//  }
//
//  a
//  {
//    text-transform: uppercase;
//    font-size: 1rem;
//    font-weight: 100;
//    font-family: $font-family-raleway;
//
//    @include media-breakpoint-up(lg)
//    {
//      font-size: $font-size-sm;
//    }
//  }
//}
//
//@include media-breakpoint-up(lg)
//{
//  & > li:not(:last-of-type)
//  {
//    margin-right: 50px!important;
//    margin-left: 0!important;
//  }
//}