#gform_1
{
  .gfield_label, .gfield_required
  {
    color: $brand-primary;
    font-family: $body-font-family;
    font-weight: normal;
    font-size: 16px;
  }

  li.gfield
  {
    margin-top: $grid-gutter-width-base * 1.5;
  }

  .gfield_required
  {
    //font-size: 10px;
    vertical-align: top;
  }

  .gform_button.button
  {
    background: $brand-primary;
    color: #fff;
    padding: .8rem 2rem;
    float: right;
    border: none;
  }

  .gform_footer
  {
    &:after
    {
      content: '';
      display: table;
      clear: both;
    }
  }
  input:not(.gform_button)
  {
    outline: none;
    border: 1px solid #4A4B4C;
    color: $brand-tertiary;
    font-family: $body-font-family;
  }

  textarea
  {
    border: 1px solid #000;
  }
}
$contact-li-spacing: 2em;

.contact-address-details-list, .contact-information-details-list
{
  padding-left: 0;
  list-style-type: none;

  li {
    color: $brand-tertiary;
    padding-left: $contact-li-spacing;
    font-family: $body-font-family;
    font-size: 1rem;

    &:not(:last-child)
    {
      margin-bottom: .5em
    }

    &:before
    {
      color: $brand-primary;
    }

    a
    {
      color: $brand-tertiary;
      font-family: $body-font-family;
      font-size: 1rem;
    }
  }

}



.address
{
   &:before {
     content: "\f051"; /* Icon Unicode */
     font-family: IcoFont; /* Icon Family */
     display: inline-block;
     margin-left: -$contact-li-spacing; /* same as padding-left set on li */
     width: $contact-li-spacing; /* same as padding-left set on li */
   }
}

.email
{
  &:before {
    content: "\f0e0"; /* Icon Unicode */
    font-family: FontAwesome; /* Icon Family */
    display: inline-block;
    margin-left: -$contact-li-spacing; /* same as padding-left set on li */
    width: $contact-li-spacing; /* same as padding-left set on li */
  }
}

.phone
{
  &:before {
    content: "\f09c"; /* Icon Unicode */
    font-family: IcoFont; /* Icon Family */
    display: inline-block;
    margin-left: -$contact-li-spacing; /* same as padding-left set on li */
    width: $contact-li-spacing; /* same as padding-left set on li */
  }
}

