.site-header
{
  position: relative;
  z-index: 10;
  //padding: $grid-gutter-width-base * 1.5 0;

  @include media-breakpoint-up(md)
  {
    padding: initial;
  }
}

.brand
{
  display: inline-block;
  padding-bottom: $grid-gutter-width-base / 2;
  position: relative;
  z-index: 99999;

  @include media-breakpoint-up(md)
  {
    padding-bottom:initial;
  }

  .brand-image
  {
    @include media-breakpoint-up(md)
    {
      max-width: 170px;
    }
  }

}

.video-header-overlay
{
  &::after
  {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $content-overlay;
    text-indent: -999;
    z-index: 0;
  }

  .header-navigation-wrapper
  {
    z-index: 10;
  }
}


//
//.videoContainer {
//  position: absolute;
//  width: 100%;
//  height: 100%;
//  top: 0;
//  left: 0;
//  bottom: 0;
//  right: 0;
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  align-items: center;
//  overflow: hidden;
//
//  iframe {
//    /* optional */
//    width: 100%;
//    height: 100%!important;
//  }
//}


.videobg {
  position: relative;
  width: 100vw; /* Set video container element width here */
  height: 100vh; /* Set video container element height here */
  overflow: hidden;
  background: #111; /* bg color, if video is not high enough */
}

/* horizontally center the video */
.videobg-width {
  position: absolute;
  width: 100%; /* Change width value to cover more area*/
  height: 100%;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

/* set video aspect ratio and vertically center */
.videobg-aspect {
  position: absolute;
  width: 100%;
  height: 0;
  top: -9999px;
  bottom: -9999px;
  margin: auto;
  padding-bottom: 56.25%; /* 16:9 ratio */
  overflow: hidden;

}

.videobg-make-height {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
}

.videobg-hide-controls {
  box-sizing: content-box;
  position: relative;
  height: 100%;
  width: 100%;
  /* Vimeo timeline and play button are ~55px high */
  padding: 55px 97.7777px; /* 16:9 ratio */
  top: -55px;
  left: -97.7777px; /* 16:9 ratio */
}

.videobg iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0 none;
}

.image-header
{
  min-height: 300px;
}

.image-header
{
  .header-navigation-wrapper
  {
    position: relative;
    top: initial;
    bottom: initial;
    left: initial;
    right: initial;
    padding-top: 30px;
    display: block;

    @include media-breakpoint-up(md)
    {
        position: absolute;
        top: $grid-gutter-width-base;
        left: 0;
        right: 0;
        width: 100%;
        padding-top: initial;
    }
  }
}

.header-feature-image-wrapper
{
  //@include aspect-ratio(5, 3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  // @include media-breakpoint-up(md)
  // {
  //   @include aspect-ratio(16, 9);
  // }

  @include media-breakpoint-up(xl)
  {
    @include aspect-ratio(4, 1);
  }

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  //max-height: 50vh;

  &::after
  {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
  }
}

.header-navigation-wrapper
{
  position: relative;

  @include media-breakpoint-up(md)
  {
    position: absolute;
    top: $grid-gutter-width-base;
    left: 0;
    right: 0;
    width: 100%;
  }
}

body.home
{
  .page-title
  {
    @include media-breakpoint-up(md)
    {
      text-align: right;
    }
  }
}

.page-title
{
  text-align: center;
  line-height: 3.75rem;
  font-size: 3.5rem;

  @include media-breakpoint-up(md)
  {
    text-align: right;
  }
}

@include media-breakpoint-up(lg)
{
  .nav-primary .nav-main-link
  {
    padding: 25px 0;
  }
}
@include media-breakpoint-up(xl)
{
  .nav-primary .nav-main-link
  {
    padding: 25px 5px;
  }
}

header.video-header.site-header
{
  padding: 20px 5px;

  @include media-breakpoint-up(lg)
  {
    padding: 0;
  }
  @include media-breakpoint-up(md)
  {
    height: 100vh;
  }

  .videobg
  {
    display: none;

    @include media-breakpoint-up(lg)
    {
      display: block;
    }
  }

  .videobg-mobile
  {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include media-breakpoint-up(lg)
    {
      display: none;
    }
  }

  .video-caption-wrapper
  {
    position: relative;
    z-index: 99999;
    margin: 40px auto;
    @include media-breakpoint-up(md)
    {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      padding: 0 15px;
      z-index: 10;
      margin: 20px auto;
      //display: flex;
      //align-items: center;
      //justify-content: center;
      display: table;

        div.video-caption-content-wrapper
        {
          min-height: initial;
          display: table-cell;
          vertical-align: middle;
        }
    }

      .video-caption-content-wrapper
      {
        display: block;
        margin: 0 auto;
        color: #fff;
        text-align: center;

        h1, h2, h3, h4, h5, h6, a, span, p, i {
          color: #fff;
        }

        & > p
        {
          max-width: 500px;
          margin: 0 auto;
        }

        & > h2 {
          @extend .h1;
        }
      }

      .header-video-play-link
      {
        display: inline-block;
        margin: 0 auto;
        color: $brand-primary;
        cursor: pointer;
        padding: 10px;
        i
        {
          color: #fff;
          padding-right: 5px;
        }
      }
  }
}

.page-header
{
  p
  {
    margin-bottom: 2rem;
  }
}