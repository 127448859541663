.block-team-slider
{
  padding-bottom: $grid-gutter-width-base * 2;
}

.profile-wrapper, .team-slider-nav
{
  max-width: 400px;

  @include media-breakpoint-up(md)
  {
    max-width: 610px;
  }

  @include media-breakpoint-up(lg)
  {
    max-width: 800px;
  }

  @include media-breakpoint-up(xl)
  {
    max-width: 1000px;
  }
}

.profile-wrapper
{
  margin: 0 auto;
  width: 100vw;
  padding-left: 15px;
  padding-right:15px;

  @include media-breakpoint-up(md)
  {
    &:after
    {
      content: '';
      display: table;
      clear: both;
    }
  }
}


.team-slider-nav
{
  max-height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  text-align: center;
  @include media-breakpoint-up(md)
  {
    text-align: right;
  }

  .team_prev, .team_next
  {
    background-color: #000;
    outline: none;
    padding: 0;
    height: 60px;
    width: 60px;
    position: relative;
    border: none;
    cursor: pointer;
    i
    {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $brand-primary;
      font-size: 2.2rem;
    }
  }
}

@include media-breakpoint-up(md)
{
  .profile-wrapper
  {
    .profile-position, .profile-details-wrapper, .profile-name, .profile-content-wrapper
    {
      text-align: left;
    }
  }
}

.profile-image
{
  display: block;
  margin: 0 auto;
  width: auto;
  min-height: 400px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  @include media-breakpoint-up(md)
  {
    max-width: 50%;
    width: 50%;
    display: inline-block;
    float: right;
    min-height: 450px;
  }

  @include media-breakpoint-up(lg)
  {
    min-height: 500px;
  }

  @include media-breakpoint-up(xl)
  {
    max-width: 400px;
  }
}

.profile-details-wrapper
{
  @include media-breakpoint-up(md)
  {
    display: inline-block;
    float: left;
    max-width: 50%;
    padding-right: 20px;
  }

  @include media-breakpoint-up(xl)
  {
    max-width: 550px;
  }
}

.profile-position
{
  width: 100%;
  display: inline-block;
  color: #979797;
  padding: 0 10px;
  font-family: $body-font-family;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 20px;

  @include media-breakpoint-up(md)
  {
    padding: 0;
    margin-top: 0;
  }
}

.profile-name
{
  text-align: center;
  font-family: $body-font-family;
  margin-bottom: 0;
  @include media-breakpoint-up(md)
  {
    font-size: 2.25rem;
    line-height: 2.625rem;
  }

}

.profile-content-wrapper
{
  margin: $grid-gutter-width-base / 2 0;
  text-align: center;

  p
  {
    color: $brand-tertiary;
  }
}

@include media-breakpoint-up(md)
{
  .team-slider .owl-item:nth-child(even)
  {
    .team-slider-slide
    {
      .profile-image
      {
        float: left;
      }
      .profile-details-wrapper
      {
        float: right;
        padding-right: 0;
        padding-left: 20px;
      }
    }
  }
}