.single-portfolio-videos-gallery-wrapper
{
  width: 100%;
  height: auto;
  margin-bottom: -$grid-gutter-width-base / 3;

  @include media-breakpoint-up(md)
  {
    &:after
    {
      content: '';
      display: table;
      clear: both;
    }
  }
}

.portfolio-single-titles-wrapper
{
  margin-bottom: $grid-gutter-width-base;
  text-align: center;
  
  @include media-breakpoint-up(md)
  {
    text-align: left;
    margin-bottom: $grid-gutter-width-base * 1.5;
  }
}
.single-portfolio-content-wrapper
{
  @include media-breakpoint-up(md)
  {
    text-align: left;
  }
}

.portfolio-element
{
  text-align: center;
  margin-bottom: $grid-gutter-width-base;

  @include media-breakpoint-up(sm)
  {
    width: 50%;
    padding: 0 $grid-gutter-width-base / 3;
    display: inline-block;
    float: left;
  }
}

.portfolio-element-thumbnail {
  height: 0;
  padding-top: 56.25%;
  position: relative;
  background-size: cover;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;

  &::after
  {
    content: "\ed89";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: icofont;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
    color: rgba(255,255,255,0.7);
    opacity: 1;
    transition: opacity .5s;
    @include media-breakpoint-up(lg)
    {
      opacity: 0;
    }
  }

  @include media-breakpoint-up(lg)
  {
    &:hover
    {
      &::after
      {
        opacity: 1;
      }
    }
  }
}

.portfolio-element-index
{
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  font-size: 13px;
}

.portfolio-testimonial-wrapper
{
  padding: $grid-gutter-width-base * 2 0;
  @include media-breakpoint-up(md)
  {
    padding: $grid-gutter-width-base * 4 0;
  }
}

.portfolio-testimonial
{
  font-weight: 600;
  font-family: $body-font-family;
  color: $brand-tertiary;

  & > p
  {
    font-style: italic;
  }
}

.portfolio-testimonial-title
{
  display: block;
  margin: 0 auto;
  text-align: center;
}

.portfolio-breadcrumb-list
{
  display: block;
  padding-left: 0;
  //padding: $grid-gutter-width-base / 3 0;
  margin-bottom: 0;
  list-style-type: none;

  @include media-breakpoint-up(md)
  {
    margin-left: -5px;
    text-align: left;
  }

}

.portfolio-breadcrumb-item{
  display: inline;
  width: auto;
  font-size: 13px;

  //@include media-breakpoint-up(md)
  //{
  //  .portfolio-breadcrumb:first-child
  //  {
  //    padding-left: 0;
  //  }
  //}
}

.portfolio-breadcrumb
{
  color: $brand-tertiary;
  padding: 0 5px;
  text-transform: uppercase;
}

.portfolio-single-breadcrumb-wrapper
{
  padding: $grid-gutter-width-base * 2 0 $grid-gutter-width-base 0;
  text-align: center;
}

.portfolio-single-categories
{
  color: #979797;
  padding-bottom: .3em;
  text-transform: uppercase;
  display: inline-block;
}


.portfolio-related-item-display-wrapper
{
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  min-height: 240px;
  height: 240px;

  &:after
  {
    content: '';
    position : absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: $content-overlay;

    transition: background-color .5s;
  }

  .portfolio-related-hyperlink
  {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    text-indent: -999px;
    cursor: pointer;
    z-index: 999;
  }

  @include media-breakpoint-up(sm)
  {
    min-height: 350px;
    height: 350px;
  }

  @include media-breakpoint-up(md)
  {
    min-height: 450px;
    height: 450px;

    .related-link
    {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: opacity 1s, max-height 1s;

    }

    &:hover
    {
      &:after
      {
        background-color: rgba(0,0,0,0.6);
      }
      .portfolio-related-item-content-wrapper
      {
        .related-link
        {
          opacity: 1;
          max-height: 60px;
        }
      }
    }
  }

  .related-link > a
  {
    color: #fff;
  }

  .portfolio-related-item-content-wrapper
  {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;
    color: #fff;

    .portfolio-related-logo
    {
      max-width: 200px;
      margin: 0px auto;
      display: block;
      max-height: 60px;
      width: auto;

      @include media-breakpoint-up(md)
      {
        max-height: 120px;
      }
    }

    .related-link
    {
      display: inline-block;
      a
      {
        i
        {
          padding-left: 10px;
        }
      }
    }
  }


  .portfolio-related-hyperlink
  {
    display: block;
  }
}

.portfolio-related-category
{
  color: #fff;
  font-weight: 100;
}

.related-hyperlink
{
  display: block;
  cursor: pointer;
}

.article-background
{

}

.article-wrapper
{
  position: relative;
  display: block;
  padding: $grid-gutter-width-base * 2 $grid-gutter-width-base;

  @include media-breakpoint-up(sm)
  {
    padding: $grid-gutter-width-base * 3 $grid-gutter-width-base * 2;
  }

  @include media-breakpoint-up(md)
  {
    min-height: 450px;
    display: flex;
    align-items: flex-end;
  }

  @include media-breakpoint-up(lg)
  {
    //min-height: 550px;
  }

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:after
  {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.6);
    transition: background-color 1s;
  }

  &:hover
  {
    &:after
    {
      background-color: rgba(0,0,0,0.5);
    }
  }

  .article-link
  {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    text-indent: -9999px;
    z-index: 1000;
    display: block;
  }

  .article-content-wrapper
  {
    position: relative;
    z-index: 10;
    text-align: center;

    @include media-breakpoint-up(sm)
    {
      text-align: left;
    }

    time.updated
    {
      display: inline-block;
      color: #fff;
      font-family: $body-font-family;
      font-size: $font-size-sm;
      font-weight: 100;
      margin-bottom: .5em;
    }

    .entry-title
    {
      a
      {
        color: $brand-primary;
        font-family: $body-font-family;
      }
    }

    .article-header, .entry-summary, .entry-title, .updated
    {
      text-align: center;
      position: relative;
      z-index: 10;

      @include media-breakpoint-up(sm)
      {
        text-align: left;
      }
    }

    .entry-summary
    {
      color: #fff;
      font-size: $font-size-sm;
      font-weight: 100;
      line-height: $line-height-lg * 1.5;
    }
  }

}

.single-post-content-wrapper
{
  @include media-breakpoint-up(md)
  {
    padding-right: $grid-gutter-width-base;
  }
}

.portfolio-testimonial-title-wrapper
{
  margin-top: $grid-gutter-width-base;
}

.portfolio-backlink
{
  border-radius: 0;
  padding-top: .8em;
  padding-bottom: .8em;
}

.posts-pagination-wrapper
{
  text-align: center;
  padding: $grid-gutter-width-base * 1.5 0;
  text-align: center;
}

.portfolio-pagination-button
{
  display: inline-block;
  padding: 1em 2em;
  background: $brand-primary;
  color: #fff;
  cursor: pointer;
  margin: 0 10px;

  &:hover
  {
    text-decoration: none;
    color: #fff;
  }
}

.single-news-entry-header
{
  margin: $grid-gutter-width-base 0;
  @include media-breakpoint-up(md)
  {
    margin: $grid-gutter-width-base * 2 0;
  }

  display: block;

  .updated
  {
    font-size: 1.375rem;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: .5em;
    display: inline-block;
    font-family: $body-font-family;
  }

  .entry-title
  {
    @include media-breakpoint-up(md)
    {
      font-size: 3.175rem;
    }
  }
}

.entry-content
{
  margin-bottom: $grid-gutter-width-base * 2;
}

.single-post-content-wrapper
{
  padding-top: 10px;
  padding-bottom: 10px;

  @include media-breakpoint-up(md)
  {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.news-pagination-wrapper
{
  padding: 50px 0;
  text-align: center;

  .prev, .next, .pages
  {
    display: inline-block;
  }

  .prev, .next
  {
    padding: 10px 20px;
    background: $brand-primary;
  }

  & > .prev {}
  & > .next {}

  .news-pagination-icon
  {
    color: #fff;
  }

  & > .pages
  {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    li
    {
      display: inline-block;
      background: $brand-primary;

      a, span
      {
        color: #fff;
        padding: 10px 20px;
        text-align: center;
        display: inline-block;
      }
    }
  }

}