.block-service-title-wrapper
{
  @include aspect-ratio(16, 9);
  @include overlay(#000, 0.5);

  @include media-breakpoint-up(md)
  {
    @include aspect-ratio(3.5, 1);
  }
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover
  {
    @include overlay(#000, 0.3);

    .services-hover-icon {
      opacity: 1;
    }
  }

  &:after
  {
    transition: background-color .3s;
  }
}

.services-hover-icon--wrapper {
  display: block;
  opacity: 1;
}

.services-hover-icon {
  opacity: 0;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  animation: bounce .5s infinite alternate;
  -webkit-animation: bounce .5s infinite alternate;
  transition: opacity .3s linear;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-10px);
  }
}

.service-block-content-wrapper
{
  visibility: hidden;
  max-height: 0;
  opacity: 0;
  transition:  max-height 1s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease-in;
  transform: translateZ(0);
  //will-change: max-height;
  //will-change: visibility;
  //will-change: opacity;
}

.service-block-content-container
{
  padding: $grid-gutter-width-base * 2 0;
  color: $brand-tertiary;
  text-align: center;
  line-height:32px;

  &:last-child
  {
    margin-bottom: 0!important;
  }

  p
  {

  }

  p:last-of-type
  {
    margin-bottom: 0;
  }
}

.services-block-title-container
{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2
  {
    color: $brand-secondary;
  }
}

.service-block-icon-container
{
  text-align: center;
  padding-bottom: $grid-gutter-width-base / 1.5;

  i
  {
    color: opacify($brand-primary, 0.8);
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      color: $brand-primary;
    }
    @include media-breakpoint-up(md)
    {
        //font-size: 2rem;
    }
  }
}

.show-content-block
{
  max-height: 1000px;
  opacity: 1;
  visibility: visible;
}