// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Glyphicons font path
$icon-font-path:        "../fonts/";

// Colors
$brand-primary: #f05123;
$brand-secondary:       #fff;
$brand-tertiary:        #231F20;
$hundred-proof-grey: #4A4B4C;
$hundred-proof-text-muted: #979797;
$content-overlay: rgba(0,0,0,0.3);

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #ffffff;

//** Global text color on `<body>`.
$body-color:            #231F20;

//** Link hover decoration.
$link-hover-decoration: none;
$link-color:            $brand-primary;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;

//** CUSTOM HUNDRED PROOF FONTS **//
$font-family-raleway: 'Raleway', sans-serif;
$font-family-open-sans: 'Open Sans', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-roboto-condensed: 'Roboto Condensed', sans-serif;
$font-family-base:        $font-family-open-sans;

$font-family-bebas-neue: 'Bebas Neue', sans-serif;
$font-family-lato: 'Lato', sans-serif;

$font-size-base: 16;
$font-size-xl: 1.5rem;
//** OLD FONT SIZES **//
//$font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
//$font-size-h2:            floor(($font-size-base * 1.95));
//$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
//$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
//$font-size-h5:            $font-size-base !default;
//$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

//** By default, this inherits from the `<body>`.
//$headings-font-family:    $font-family-raleway;
$headings-font-family:    $font-family-bebas-neue;
$headings-font-weight:    500;
$headings-line-height:    1.1;
$headings-color:          $brand-primary;

// Body
$body-font-family: $font-family-lato;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-default-color:              #fff;
$btn-default-bg:                 $brand-primary;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;

//$btn-success-color:              #fff !default;
//$btn-success-bg:                 $brand-success !default;
//$btn-success-border:             darken($btn-success-bg, 5%) !default;
//
//$btn-info-color:                 #fff !default;
//$btn-info-bg:                    $brand-info !default;
//$btn-info-border:                darken($btn-info-bg, 5%) !default;

//$btn-link-disabled-color:        $gray-light !default;


